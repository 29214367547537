import { Component } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'app-close-window',
  templateUrl: './close-window.component.html',
  styleUrls: ['./close-window.component.css'],
})
export class CloseWindowComponent {
  constructor() {
    window.close();
  }
}
