import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { TimePickerComponent } from './time-picker.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, NgxDatatableModule, NgxTippyModule],
  declarations: [TimePickerComponent],
})
export class TimePickerModule {}
