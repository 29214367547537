<ion-content>
  <ion-list lines="none">
    <ion-item>
      <ion-label>
        <h1>{{name}}</h1>
        <p>{{email}}</p>
        <p *ngIf="!authService.tenantId">
          <span *ngIf="!isBusiness"> Personal account </span>
          <span *ngIf="isBusiness"> Business account </span>
        </p>
      </ion-label>
    </ion-item>
    <ion-item button [routerLink]="'profile'" routerDirection="root" (click)="popoverCtrl.dismiss()" class="border-top">
      Profile
    </ion-item>
    <ion-item button (click)="openHelpdesk()">
      Helpdesk
      <ion-icon name="open-outline" class="icon-padded-left"></ion-icon>
    </ion-item>
    <ion-item button (click)="sendFeedback()"> Feedback </ion-item>
    <ion-item button [routerLink]="'login'" routerDirection="root" (click)="logout()" class="border-top">
      <ion-label color="danger"> Logout </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
