import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { RouterLinkDelegate } from '@ionic/angular';
import * as Sentry from '@sentry/angular-ivy';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://103ef6b6939c4d43ae065aa46ac64e87@o563911.ingest.sentry.io/5704361',
  environment: environment.environment,
  enabled: environment.environment === 'prd',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['https://*.unthread.io', 'https://unthread.io'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

// HACK: Fix for bug in ionic: https://github.com/ionic-team/ionic-framework/issues/24413
// Fix was copied from here: https://github.com/ionic-team/ionic-framework/pull/24440
RouterLinkDelegate.prototype.onClick = function (ev: MouseEvent) {
  if (!this.elementRef.nativeElement.closest('ion-button')) {
    // In case of CTRL+Click or Meta+Click,
    // ignore those to allow default browser actions.
    if (ev.button === 0 && (ev.ctrlKey || ev.metaKey)) {
      return;
    }
  }

  this.navCtrl.setDirection(this.routerDirection, undefined, undefined, this.routerAnimation);
  ev.preventDefault();
};

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
