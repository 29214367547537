<ion-header>
  <ion-toolbar>
    <ion-title>Notifications</ion-title>
    <ion-buttons slot="end" *ngIf="unreadCount">
      <ion-button (click)="markRead()"> Mark all read </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="notifications.length">
    <ion-item *ngFor="let notification of notifications" (click)="selectNotification(notification)" button="true">
      <ion-label text-wrap>
        <p class="title">
          <ion-icon [name]="getIcon(notification)" class="padded-icon"></ion-icon>{{ notification.title }}
        </p>
        <h3 [ngClass]="{ unread: !notification.read }">{{ notification.message }}</h3>
        <p style="font-size: 0.7rem" [ngClass]="{ unread: !notification.read }">
          {{ notification.createdAt.toDate() | date: 'MMM d h:mm a' }}
        </p>
      </ion-label>
      <ion-icon name="ellipse" slot="end" *ngIf="!notification.read" class="unread-icon"></ion-icon>
    </ion-item>
  </ion-list>
  <div class="empty-state-container" *ngIf="!notifications.length">
    <span class="placeholder-emoji">💬</span>
    <h3 class="placeholder-subtitle">Nothing to see here</h3>
  </div>
</ion-content>

<ion-footer *ngIf="unreadCount"> </ion-footer>
