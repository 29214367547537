import { Component } from '@angular/core';
import { Firestore, writeBatch, doc } from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore';
import { NavController, NavParams, PopoverController } from '@ionic/angular';

import { USER_NOTIFICATION_TYPES, routes, collections } from 'src/app/constants';
import { UserNotification } from 'src/models';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
  notifications: UserNotification[] = [];
  unreadCount = 0;

  constructor(
    private afs: Firestore,
    private navController: NavController,
    private popoverCtrl: PopoverController,
    navParams: NavParams,
  ) {
    this.notifications = navParams.get('notifications');
    this.unreadCount = navParams.get('unreadCount');

    // used to automatically mark read because notifications were not too useful
    // Now that we don't send new channel member notifications, maybe we can let them actually mark them as unread
    // this.markRead();
  }

  selectNotification(notification: UserNotification) {
    switch (notification.type) {
      case USER_NOTIFICATION_TYPES.NEW_INVITE:
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_FOLLOW:
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_UNFOLLOW:
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_UPLOAD_SUCCEEDED:
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_UPLOAD_FAILED: {
        this.navController.navigateRoot(
          `${routes.DASHBOARD}/${routes.CUSTOMERS}/${notification.data.organizerId}/members`,
        );
        break;
      }
      case USER_NOTIFICATION_TYPES.OUTBOUND_SURVEY_RECEIVED: {
        if (notification.data.outboundId) {
          this.navController.navigateRoot(
            `${routes.DASHBOARD}/outbound/messages?outboundId=${notification.data.outboundId}&tab=surveyResults`,
          );
        } else {
          this.navController.navigateRoot(
            `${routes.DASHBOARD}/outbound/surveys?surveyId=${notification.data.surveyId}`,
          );
        }

        break;
      }
      case USER_NOTIFICATION_TYPES.CREATE_CUSTOMER_FAILED: {
        window.open('https://docs.unthread.io/account-setup/adding-unthread#troubleshooting', '_blank');
        break;
      }
      case USER_NOTIFICATION_TYPES.CREATE_CUSTOMER_SUCCEEDED: {
        this.navController.navigateRoot(`${routes.DASHBOARD}/customers`);
        break;
      }
      case USER_NOTIFICATION_TYPES.EXTERNAL_TASK_COMPLETED: {
        this.navController.navigateRoot(
          `${routes.DASHBOARD}/${routes.INBOX}/me/conversations/${notification.data.conversationId}`,
        );
        break;
      }
      case USER_NOTIFICATION_TYPES.OUTBOUND_SEND_FAILED: {
        if (notification.data.outboundId) {
          this.navController.navigateRoot(
            `${routes.DASHBOARD}/outbound/messages?outboundId=${notification.data.outboundId}`,
          );
        }

        break;
      }
      case USER_NOTIFICATION_TYPES.OUTBOUND_SEND_SUCCEEDED: {
        if (notification.data.outboundId) {
          this.navController.navigateRoot(
            `${routes.DASHBOARD}/outbound/messages?outboundId=${notification.data.outboundId}`,
          );
        }

        break;
      }
      default:
        break;
    }

    this.markRead(notification);
    this.popoverCtrl.dismiss();
  }

  markRead(notification?: UserNotification) {
    const batch = writeBatch(this.afs);

    if (notification) {
      notification.read = true;
      notification.readAt = Timestamp.fromDate(new Date());

      batch.update(doc(this.afs, `${collections.NOTIFICATIONS}/${notification.objectId}`), {
        read: true,
        readAt: Timestamp.fromDate(new Date()),
      });
    } else {
      this.notifications.forEach((n) => {
        n.read = true;
        n.readAt = Timestamp.fromDate(new Date());

        batch.update(doc(this.afs, `${collections.NOTIFICATIONS}/${n.objectId}`), {
          read: true,
          readAt: Timestamp.fromDate(new Date()),
        });
      });
    }

    batch.commit();
  }

  getIcon(notification: UserNotification) {
    switch (notification.type) {
      case USER_NOTIFICATION_TYPES.NEW_INVITE:
        return 'calendar-outline';
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_FOLLOW:
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_UNFOLLOW:
        return 'people-circle-outline';
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_UPLOAD_FAILED:
        return 'cloud-offline-outline';
      case USER_NOTIFICATION_TYPES.COMMUNITY_MEMBER_UPLOAD_SUCCEEDED:
        return 'cloud-done-outline';
      case USER_NOTIFICATION_TYPES.OUTBOUND_SURVEY_RECEIVED:
        return 'list-outline';
      case USER_NOTIFICATION_TYPES.CREATE_CUSTOMER_SUCCEEDED:
        return 'business-outline';
      case USER_NOTIFICATION_TYPES.CREATE_CUSTOMER_FAILED:
        return 'alert-circle-outline';
      case USER_NOTIFICATION_TYPES.EXTERNAL_TASK_COMPLETED:
        return 'checkbox-outline';
      case USER_NOTIFICATION_TYPES.BULK_CONVERSATION_UPDATE_SUCCEEDED:
        return 'checkmark-outline';
      case USER_NOTIFICATION_TYPES.BULK_CONVERSATION_UPDATE_FAILED:
        return 'alert-circle-outline';
      case USER_NOTIFICATION_TYPES.OUTBOUND_SEND_SUCCEEDED:
        return 'megaphone-outline';
      case USER_NOTIFICATION_TYPES.OUTBOUND_SEND_FAILED:
        return 'alert-circle-outline';
      default:
        return 'calendar-outline';
    }
  }
}
