import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { EditTagComponent } from './edit-tag.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule],
  declarations: [EditTagComponent],
  exports: [EditTagComponent],
})
export class EditTagModule {}
