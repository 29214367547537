import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { AuthService } from 'src/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SuperAdminGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    await new Promise((resolve) => {
      this.authService.onAuthReady(() => {
        resolve(undefined);
      });
    });

    if (!this.authService.isSuperAdmin) {
      this.router.navigate(['/login'], {
        queryParams: {
          redirect: document.location.pathname,
        },
      });

      return false;
    }

    return true;
  }
}
