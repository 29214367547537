import { Injectable, OnDestroy } from '@angular/core';
import { NavController } from '@ionic/angular';
import { init } from 'commandbar';

init('ba91b99a');

@Injectable({
  providedIn: 'root',
})
export class CommandbarService implements OnDestroy {
  constructor(private navController: NavController) {}

  init() {
    const loggedInUserId = '12345'; // example
    window.CommandBar?.boot(loggedInUserId);
    window.CommandBar?.addRouter((url: string) => {
      return this.navController.navigateRoot(url);
    });

    window.CommandBar?.addCallback('contactSupport', (arg, context) => {
      (window as any).$unthread.inAppChat('toggle', 'show');
    });
  }

  ngOnDestroy(): void {
    window.CommandBar?.shutdown();
  }
}
