import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { EditTagLinksComponent } from './edit-tag-links.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule],
  declarations: [EditTagLinksComponent],
  exports: [EditTagLinksComponent],
})
export class EditTagLinksModule {}
